// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-acl-js": () => import("/opt/build/repo/src/pages/case-study/acl.js" /* webpackChunkName: "component---src-pages-case-study-acl-js" */),
  "component---src-pages-case-study-design-leadership-js": () => import("/opt/build/repo/src/pages/case-study/design-leadership.js" /* webpackChunkName: "component---src-pages-case-study-design-leadership-js" */),
  "component---src-pages-case-study-venture-deal-js": () => import("/opt/build/repo/src/pages/case-study/venture-deal.js" /* webpackChunkName: "component---src-pages-case-study-venture-deal-js" */),
  "component---src-pages-case-study-visual-design-js": () => import("/opt/build/repo/src/pages/case-study/visual-design.js" /* webpackChunkName: "component---src-pages-case-study-visual-design-js" */),
  "component---src-pages-case-study-zini-vng-js": () => import("/opt/build/repo/src/pages/case-study/zini-vng.js" /* webpackChunkName: "component---src-pages-case-study-zini-vng-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

